body {
  margin: 0;
  font-family: -apple-system, 'Causten-Regular', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: 'Causten-Regular', sans-serif;
}

@font-face {
  font-family: "Causten-Thin";
  src: url("./assets/fonts/CaustenRound/CaustenRound-Thin.ttf");
}

@font-face {
  font-family: "Causten-Regular";
  src: url("./assets/fonts/CaustenRound/CaustenRound-Regular.ttf");
}

@font-face {
  font-family: "Causten-Bold";
  src: url("./assets/fonts/CaustenRound/CaustenRound-Bold.ttf");
}

@font-face {
  font-family: "Causten-Black";
  src: url("./assets/fonts/CaustenRound/CaustenRound-Black.ttf");
}

@font-face {
  font-family: "Causten-ExtraBold";
  src: url("./assets/fonts/CaustenRound/CaustenRound-ExtraBold.ttf");
}

@font-face {
  font-family: "Causten-ExtraLight";
  src: url("./assets/fonts/CaustenRound/CaustenRound-ExtraLight.ttf");
}

@font-face {
  font-family: "Causten-Light";
  src: url("./assets/fonts/CaustenRound/CaustenRound-Light.ttf");
}

@font-face {
  font-family: "Causten-Medium";
  src: url("./assets/fonts/CaustenRound/CaustenRound-Medium.ttf");
}

@font-face {
  font-family: "Causten-SemiBold";
  src: url("./assets/fonts/CaustenRound/CaustenRound-SemiBold.ttf");
}

.notistack-SnackbarContainer {
  z-index: 1500000000000023020203023023023023023023023032023000;
}

iframe {
  overflow: auto;
}

#notistack-snackbar {
  z-index: 9999;
}

/* CalendarStyles.css */
.fc {
  background-color: #f8f8f8;
  font-family: 'Causten-Regular', sans-serif;
}

.fc-toolbar {
  background-color: #f8f8f8;
}

.fc-daygrid-day {
  background-color: #f8f8f8;
}

.fc-toolbar-title {
  font-family: 'Causten-Medium', sans-serif;
  padding-top: 5px;
  padding-left: 5px;
}

.fc .fc-daygrid-day-frame {
  height: 50px;
}

.fc .fc-button {
  border-radius: 12px;
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: #f8f8f8;
  font-weight: 900;
  text-decoration: underline;
}