.react-calendar {
    /* width: 400px; */
    /* max-width: 100%; */
    background-color: #fff;
    font-family: "Causten-SemiBold";

    /* hafta isimleri*/
    color: #222;

    /* border-radius: 8px; */
    /* box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2); */
    font-family: "Causten-Medium";
    /* font-size: 14px; */
    line-height: 1.125em;
    border: none !important;
    /* padding: 10px; */
    /* max-width: 100%; */
    /* height: 100%; */

    .react-calendar__navigation button {
        color: #333;
        min-width: 44px;
        background: none;
        font-size: 16px;
        margin-top: 8px;
        font-family: "Causten-SemiBold";

        /* background-color: red; */
    }

    /* for days name make capitalize */
    .react-calendar__month-view__weekdays__weekday {
        text-transform: capitalize;
        font-family: "Causten-SemiBold";
    }

    .react-calendar__navigation button:enabled:hover,
    .react-calendar__navigation button:enabled:focus {
        background-color: #f8f8fa;
        font-family: "Causten-SemiBold";
    }

    .react-calendar__navigation button[disabled] {
        background-color: #f0f0f0;
        font-family: "Causten-SemiBold";
    }

    abbr[title] {
        text-decoration: none;
    }

    /* .react-calendar__month-view__days__day--weekend {
      color: #d10000;
     } */
    .react-calendar__tile {
        /* background-color: red; */
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 30px !important;
        padding: 10px !important;
        font-family: "Causten-Medium";
    }

    .react-calendar__tile>* {
        /* background-color: red; */
        margin: 0 !important;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .react-calendar__tile:enabled:hover,
    .react-calendar__tile:enabled:focus {
        /* ok */
        background: #ffead7;
        color: #ffc087;
        border-radius: 50%;
        /* background-color: yellow; */
    }

    .react-calendar__tile--now {
        /* ok */
        background: #ffead7;
        border-radius: 50%;
        font-weight: bold;
        color: #ffc087;
    }

    .react-calendar__tile--now:enabled:hover,
    .react-calendar__tile--now:enabled:focus {
        background: #fedec3;
        border-radius: 6px;
        font-weight: bold;
        color: #ffb371;
        border-radius: 50%;
    }

    .react-calendar__tile--hasActive:enabled:hover,
    .react-calendar__tile--hasActive:enabled:focus {
        background: #f8f8fa;
    }

    .react-calendar__tile--active {
        background: #ff9737;
        border-radius: 6px;
        font-weight: bold;
        color: white !important;
    }

    .react-calendar__tile--active:enabled:hover,
    .react-calendar__tile--active:enabled:focus {
        background: #ff9737;
        color: white;
        border-radius: 50%;
    }

    .react-calendar--selectRange .react-calendar__tile--hover {
        background-color: #f8f8fa;
        background: #ff9737;
    }

    .react-calendar__tile--range {
        background: #f8f8fa;
        color: #ff9737;
        /* border-radius: 0; */
    }

    .react-calendar__tile--rangeStart {
        /* border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px; */

        border-radius: 50%;
        background: #ff9737;
        color: white;
    }

    .react-calendar__tile--rangeEnd {
        /* border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px; */

        border-radius: 50%;
        /* display: flex !important; */
        background: #ff9737;
        color: white;
    }

    /* for select month */
    .react-calendar__tile.react-calendar__tile--now.react-calendar__tile--hasActive.react-calendar__year-view__months__month {
        background-color: transparent;
    }

    .react-calendar__tile.react-calendar__year-view__months__month:hover {
        background-color: transparent;
    }

    /* for select year */
    .react-calendar__tile.react-calendar__tile--now.react-calendar__tile--hasActive.react-calendar__decade-view__years__year {
        background-color: transparent;
    }

    .react-calendar__tile.react-calendar__decade-view__years__year {
        background-color: transparent !important;
    }

    /* for select decade */
    .react-calendar__tile.react-calendar__tile--now.react-calendar__tile--hasActive.react-calendar__century-view__decades__decade {
        background-color: transparent;
    }

    .react-calendar__tile.react-calendar__century-view__decades__decade {
        background-color: transparent !important;
    }

    /* for red weekend*/
    .react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend {
        color: #ff9737;
    }

    /* for fade before month days*/
    .react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth {
        color: #96a1ba;
    }

    .react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth:hover {
        color: #ff9737;
    }

    .low {
        /* color:red; */
        background-color: #00b543;
        border-radius: 50%;
        /* display: flex !important; */
        /* width: 30px!important; */
        /* height: 30px!important; */

        color: #fff !important;
    }

    .normal {
        /* color:red; */
        background-color: #eba900;
        border-radius: 50%;
        /* display: flex !important;
        width: 30px!important;
        height: 30px!important; */
        color: #fff !important;
    }

    .high {
        /* color:red; */
        background-color: #ff5a37;
        border-radius: 50%;
        /* display: flex !important;
        width: 30px!important;
        height: 30px!important; */
        color: #fff !important;
    }

    .multipleTaskInADate {
        /* color:red; */
        /* background: linear-gradient(217deg, #00B543, #00B543 70.71%),
                linear-gradient(127deg, #EBA900, #EBA900 70.71%),
                linear-gradient(336deg, #FF5A37, #FF5A37 70.71%); */
        background: linear-gradient(to right top, #eba900, #00b543, #ff5a37);
        border-radius: 50%;
        /* display: flex !important;
        width: 30px!important;
        height: 30px!important; */
        color: #fff !important;
    }

    /* .react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth {
        background-color: green;
    } */
    .react-calendar__tile.react-calendar__month-view__days__day {
        /* background-color: black; */
        display: flex !important;
        margin: 0 !important;
    }

    .react-calendar__month-view__days {
        /* gap: 2px; */
        /* align-items: center; */
        /* justify-content: center; */
    }
}