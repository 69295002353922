/* Basic editor styles */

.tiptap_editor {

  height: 100%;

  & * {
    margin: 0px;
  }


  &:hover>* {
    border-color: #555 !important;
    outline: #555 !important;
  }

  &:hover>*>* {
    border-color: #555 !important;
    outline: #555 !important;
  }

  &:active>* {
    border-color: #1857af !important;
    outline: #1857af !important;
  }

  &:active>*>* {
    border-color: #1857af !important;
    outline: #1857af !important;
  }

  .tiptap_header {
    // background-color: red;
    padding: 10px;
    // margin-bottom: 10px;
    border: 1px solid #ccc;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;

    button {}

    .is-active {
      background-color: rgba(231, 118, 118, 0.2);
    }
  }

  .ProseMirror {
    // background-color: red;
    min-height: 250px;
    max-height: 500px;
    height: 100%;
    overflow: auto;
    border: 1px solid #ccc;
    border-top: none;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 10px;

    &:focus {
      border-color: #ccc;
      outline: #ccc;
    }

    // > * + * {
    //   margin-top: 0.05em;
    // }

    ul,
    ol {
      padding: 0 1rem;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      line-height: 1.1;
    }

    code {
      background-color: rgba(#616161, 0.1);
      color: #616161;
    }

    pre {
      background: #0d0d0d;
      color: #fff;
      font-family: "JetBrainsMono", monospace;
      padding: 0.75rem 1rem;
      border-radius: 0.5rem;

      code {
        color: inherit;
        padding: 0;
        background: none;
        font-size: 0.8rem;
      }
    }

    img {
      max-width: 100%;
      height: auto;
    }

    blockquote {
      padding-left: 1rem;
      border-left: 2px solid rgba(#0d0d0d, 0.1);
    }

    hr {
      border: none;
      border-top: 2px solid rgba(#0d0d0d, 0.1);
      margin: 2rem 0;
    }

    h1 {
      font-size: 3rem;
    }

    h2 {
      font-size: 2.5rem;
    }

    h3 {
      font-size: 2rem;
    }

    h4 {
      font-size: 1.5rem;
    }

    h5 {
      font-size: 1.25rem;
    }

    h6 {
      font-size: 1rem;
    }

    ol {
      list-style: decimal;
    }

    ul {
      list-style: disc;
    }


  }
}